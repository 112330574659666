.icon-style {
    font-size: 20px;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header {
    padding: 0px !important;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header {
display: block !important;
}
.ant-input[disabled] {
    color: #a4a9ab !important;
    background-color: #f9f9f9 !important;
}
.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    color: #a4a9ab !important;
    background-color: #f9f9f9 !important;
}
.rdw-editor-wrapper {
    border: 1px solid #dfe6e9 !important;
}

:where(.css-dev-only-do-not-override-2q8sxy).ant-table-wrapper .ant-table-thead>tr>th,
:where(.css-dev-only-do-not-override-2q8sxy).ant-table-wrapper .ant-table-thead>tr>td {
    background: #F9FAFC !important;
    padding-top: 15px !important;
    padding-bottom: 15px !important;
}
.ant-table-cell {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
    min-width: 100px ;
}


.ant-table-container {
    border: 1px solid #C9D6DF !important;
    border-radius: 10px;
}

:where(.css-dev-only-do-not-override-2q8sxy).ant-modal .ant-modal-content {
    padding: 0px !important;
}

.ant-modal-header {
    border-radius: 10px 10px 0px 0px !important;
    border-bottom: 1px solid #C9D6DF !important;
    background: #FFF !important;
    padding-top: 20px !important;
    padding-bottom: 20px !important;
    padding-left: 20px !important;
}

.ant-modal-title {
    color: #1E2022 !important;
    font-size: 24px !important;
    font-style: normal !important;
    font-weight: 700 !important;
    line-height: normal !important;
}
.ant-modal-title .ant-typography{
    font-size: 20px !important;
    color: #1E2022 !important;
}

.ant-modal-close{
    border-radius: 1000px !important;
    border: 1px solid #EA4335 !important;
    background: rgba(234, 67, 53, 0.10) !important;
    width: 30px !important;
    height: 30px !important;
    margin-top: 5px !important;
}

.ant-modal-body {
    padding-left: 30px !important;
    padding-right: 30px !important;
}

.ant-modal-footer {
    border-radius: 0px 0px 10px 10px;
    border-top: 1px solid #C9D6DF;
    background: #FFF;
    padding-top: 20px;
    padding-bottom: 20px !important;
    padding-left: 20px;
    padding-right: 20px;
}

.custom-table{
    overflow: scroll;
}
.basetable-container{
    padding-top: 20px;
    padding-bottom: 50px;
    max-height: 70vh;
    overflow: scroll;
    padding-right: 20px;
}
.basetable-container::-webkit-scrollbar {
    width: 5px !important;
    height: 5px !important;
    display: none;
}
.basetable-container::-webkit-scrollbar-track {
    background: #F9FAFC;
}

.basetable-container::-webkit-scrollbar-thumb {
    background-color: #d7dfc9;
    border-radius: 20px;
    border: 1px solid #C9D6DF;
}


.ant-table-body::-webkit-scrollbar {
    width: 5px !important;
    height: 5px !important;
}



.ant-table-body::-webkit-scrollbar-track {
    background: #F9FAFC;
}

.ant-table-body::-webkit-scrollbar-thumb {
    background-color: #d7dfc9;
    border-radius: 20px;
    border: 1px solid #C9D6DF;
}
/* tile uploaded pictures */
.upload-list-inline .ant-upload-list-item {
    float: left;
    width: 200px;
    margin-inline-end: 8px;
}

.ant-upload-rtl.upload-list-inline .ant-upload-list-item {
    float: right;
}

.ant-table-cell{
    padding-left: 20px !important;
    padding-bottom: 20px !important;
    padding-top: 20px !important;
}

.img-circle{
    width: 150px; /* Adjust the size as needed */
    height: 150px; /* Adjust the size as needed */
    border-radius: 50%; /* Makes the image circular */
    object-fit: cover; /* Ensures the image covers the container without distortion */
    border: 2px solid #ddd; /* Optional: adds a border around the image */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: adds a subtle shadow */
}


.divide1_2 {
    width: 100%;
    height: 40px;
    transition: 0.2s;
    background: url(../icons/icon1_2.svg) no-repeat;
    background-size: 133px;
    cursor: pointer;

    }
  
    .divide1_4 {
    width: 100%;
    height: 40px;
    transition: 0.2s;
    background: url(../icons/icon1to4.svg) no-repeat;
    background-size: 133px;
    cursor: pointer;
    }
  
    .divide3_1 {
    width: 100%;
    height: 40px;
    transition: 0.2s;
    background: url(../icons/icon3by1.svg) no-repeat;
    background-size: 133px;
    cursor: pointer;
    }
  
    .divide3_3 {
    width: 100%;
    height: 40px;
    transition: 0.2s;
    background: url(../icons/icon3by3.svg) no-repeat;
    background-size: 133px;
    cursor: pointer;
    }



    .basetable-salarysheet-container{
        padding-top: 20px;
        padding-bottom: 50px;
        max-height: 70vh;
        overflow: scroll;
        padding-right: 20px;
    }
    .basetable-salarysheet-container::-webkit-scrollbar {
        width: 5px !important;
        height: 8px !important;
    }
    .basetable-salarysheet-container::-webkit-scrollbar-track {
        background: #F9FAFC;
    }
    
    .basetable-salarysheet-container::-webkit-scrollbar-thumb {
        background-color: #d7dfc9;
        border-radius: 20px;
        border: 1px solid #C9D6DF;
    }